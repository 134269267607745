






































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import { ListUser, ADD_USER } from "../store/users";
import { GET_ACDB, FETCH_SINGLE_ACDB } from "../store/acdb";
import { GET_CURRENT_USER } from "../store/users";
import i18n from "@/i18n";
import { filter, uniq } from "lodash-es";

function getForbiddenChar(v: string) {
  return (
    uniq(filter(v, char => !/^[a-zA-ZÀ-ÿ0-9, ,',.,+,@,-]*$/g.test(char)))
      // add ", " between illegal characters
      .join(", ")
  );
}

export default Vue.extend({
  name: "UserAddDialog",
  props: {
    exitAddUserForm: {
      type: Function,
      required: false
    },
    attributesToAdd: {
      type: Array || undefined,
      required: false
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      user: {} as ListUser,
      formValid: false,
      lazy: false,
      actif: true,
      errorMessage: "",
      loginErrorMessages: "",
      loginRules: [
        (v: string) => !!v || i18n.t("mandatoryField"),
        (v: string) =>
          /^[a-zA-ZÀ-ÿ0-9, ,',.,+,@,-]*$/g.test(v) ||
          i18n.t("forbiddenCharacters", { forbiddenChar: getForbiddenChar(v) })
      ],
      firstNameRules: [
        (v: string) => !!v || i18n.t("mandatoryField"),
        (v: string) =>
          /^[a-zA-ZÀ-ÿ0-9, ,',.,+,@,-]*$/g.test(v) ||
          i18n.t("forbiddenCharacters", { forbiddenChar: getForbiddenChar(v) })
      ],
      nameRules: [
        (v: string) => !!v || i18n.t("mandatoryField"),
        (v: string) =>
          /^[a-zA-ZÀ-ÿ0-9, ,',.,+,@,-]*$/g.test(v) ||
          i18n.t("forbiddenCharacters", { forbiddenChar: getForbiddenChar(v) })
      ],
      emailRules: [
        // The email must fullfill the regexp or be an empty string (or null), otherwise it triggers the error msg
        (v: string | null | undefined) =>
          v === null ||
          v === undefined ||
          /.+@.+\..+/.test(v as string) ||
          (v as string).length === 0 ||
          i18n.t("invalidEmail"),
        (v: string) =>
          /^[a-zA-ZÀ-ÿ0-9, ,',.,+,@,-]*$/g.test(v) ||
          i18n.t("forbiddenCharacters", { forbiddenChar: getForbiddenChar(v) })
      ],
      passwordRules: [(v: string) => !!v || i18n.t("mandatoryField")]
    };
  },
  beforeMount() {
    // Make sure the right to update the users are fetched and in the store
    if (this.getAcdb("create", "user") === undefined)
      this.fetchSingleAcdb("create", "user");
  },
  methods: {
    ...mapActions({
      fetchSingleAcdb: FETCH_SINGLE_ACDB,
      addUser: ADD_USER
    }),
    activeChanged(value: boolean) {
      this.user.active = value;
    },
    canAddUser(user: ListUser) {
      // Has the user the permission to update any user ?
      // Or its own user ?
      return this.getAcdb("create", "user").hasAccess === true;
    },
    canAddAttribute(attributeName: string) {
      if (this.attributesToAdd && this.attributesToAdd.length !== 0) {
        // If the current user wants to update it's own information
        return this.attributesToAdd.includes(attributeName);
      }
    },
    async save() {
      try {
        await this.addUser(this.user);
        this.loginErrorMessages = "";
        (this.$refs.addUserForm as any).reset();
        this.exitAddUserForm();
      } catch (error) {
        this.errorMessage = error;
        this.loginErrorMessages = this.$t(
          `users.${this.errorMessage}`
        ).toString();
      }
    },
    cancelAddUser() {
      this.loginErrorMessages = "";
      (this.$refs.addUserForm as any).reset();
      this.actif = true;
      this.exitAddUserForm();
    }
  },
  computed: {
    ...mapGetters({
      getAcdb: GET_ACDB,
      currentUser: GET_CURRENT_USER
    })
  },
  watch: {
    "$i18n.locale"() {
      this.loginErrorMessages = this.$t(
        `users.${this.errorMessage}`
      ).toString();
    },
    show: function(newVal, oldVal) {
      if (newVal === true && this.$refs.addUserForm) {
        this.user = {} as ListUser;
        (this.$refs.addUserForm as any).reset();
        (this.$refs.addUserForm as any).resetValidation();
      }
    }
  }
});
